.loaderContainer {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 20;
    color: white; 
    text-align: center;
    width: 100%;
    height: 100vh;
    font-family: 'Raleway', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: black;
    overflow: hidden;
}

.loader5 {
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    width: 100px;
    height: 100px;
    margin-bottom: 5px;
}

.panel {
    width: 101px;
    height: 101px;
    position: absolute;
}

#panel1 {
    background-color: #222;
}

#panel2 {
    background-color: #111;
}

#panel3 {
    background-color: black;
}

#backImage {
    height: 98%;
    height: 98%;
    background-color: black;
    position: absolute;
    top: 1px;
    left: 1px;
}